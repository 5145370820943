import React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Button, Typography } from 'amphitheatre';

import * as Styled from '../pages-styles/shared.styled';
import {
  BackgroundWrapper,
  BLCircle,
  TLCircle,
  BRBigCircle,
  BRSmallCircle,
} from '../components/Background';
import Layout from '../components/Layout';
import Image from '../components/Image';
import HIWList from '../components/HIWList';

import { Columns } from '../pages-styles/shared.styled';

import imgSrc from '../images/DandelionsGyroschute.jpg';

const { Header, Paragraph } = Typography;

const HowItWorks: React.FunctionComponent = () => {
  return (
    <Layout>
      <Helmet>
        <title>Wolfpack - How It Works</title>
      </Helmet>
      <BackgroundWrapper>
        <BLCircle />
        <TLCircle />
        <BRBigCircle />
        <BRSmallCircle />
      </BackgroundWrapper>
      <Styled.Container>
        <Header className="page-header">HOW IT WORKS?</Header>
        <Paragraph>The Wolfpack Space Incubator has 3 simple rules!</Paragraph>
        <Columns>
          <div style={{ flexGrow: 1, flexBasis: '60%' }}>
            <HIWList
              number="1"
              content="All companies “hunt as a pack” and actively chase customers together. Companies join each other for contracts, grants, and fund each other through supply relationships."
            />
            <HIWList
              number="2"
              content="Space and tech- and manufacturing-heavy companies only. No UAVs, no software-only, no downstream data plays - those services are welcomed on a collaborative (customer channel) basis via other accelerator programs in Australia. The Wolfpack Space Hub is focused on upstream space companies, both civil and defence, and has high aspirations for the industry's future."
            />
            <HIWList
              number="3"
              content="The focus is customers. While investors are certainly welcome (and invited) to have access to opportunities to invest in the companies the main focus is finding the quickest path to customers and contracts."
            />
          </div>
          <div style={{ flexBasis: '40%' }}>
            <Image
              caption="Dandelions’ Gyrochute re-entry space vehicle."
              src={imgSrc}
            />
          </div>
        </Columns>
        <Paragraph style={{ lineHeight: '1.5rem' }}>
          Wolfpack takes a 5% equity stake which includes a warranty program.
          Wolfpack will return 3% of equity in cases where we are not able to
          help the company raise the funds within a year.
        </Paragraph>
        <Link to="/startups">
          <Button style={{ marginTop: '0.2rem' }}>
            Who's currently involved?
          </Button>
        </Link>
      </Styled.Container>
    </Layout>
  );
};

export default HowItWorks;
