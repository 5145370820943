import React from 'react';
import * as Style from './Image.styled';

interface Props {
  caption?: string;
  imgStyle?: React.CSSProperties;
  src: string;
}

const Image: React.FunctionComponent<Props> = ({
  caption,
  imgStyle,
  src,
}: Props) => {
  return (
    <Style.Container>
      <Style.Image style={imgStyle} src={src} alt={caption} />
      <Style.Caption>{caption}</Style.Caption>
    </Style.Container>
  );
};

export default Image;
