import React from 'react';
import styled from 'styled-components';

interface Props {
  number: string;
  content: string;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2rem;
`;

const Icon = styled.div`
  border: 4px solid white;
  border-radius: 50%;

  font-family: ${({ theme }): string => theme.fontHeader};
  font-size: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;

  margin-right: 1rem;

  width: 48px;
  min-width: 48px;
  height: 48px;
  min-height: 48px;

  @media only screen and (min-width: 768px) {
    border: 8px solid white;

    font-size: 2rem;

    width: 64px;
    min-width: 64px;
    height: 64px;
    min-height: 64px;
  }
`;

const Content = styled.div`
  color: ${({ theme }): string => theme.primary800};
  font-family: ${({ theme }): string => theme.fontBody};
`;

const HIWList: React.FunctionComponent<Props> = ({
  number,
  content,
}: Props) => {
  return (
    <Wrapper>
      <Icon>{number}</Icon>
      <Content>{content}</Content>
    </Wrapper>
  );
};

export default HIWList;
