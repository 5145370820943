import styled from 'styled-components';

export const BackgroundWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  min-height: 100vh;

  max-width: 100vw;
  height: 100%;
  width: 100vw;
  min-width: 100vw;

  overflow: hidden;
  z-index: -1;
`;

export const TLCircle = styled.div`
  background-color: ${({ theme }): string => theme.primary300};
  border-radius: 50%;

  position: absolute;

  height: 61.458vw;
  width: 61.458vw;

  left: -12.344vw;
  top: -32vh;

  z-index: -3;

  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
    height: 80vw;
    width: 80vw;

    left: -28vw;
    top: -16vh;
  }
`;

export const BLCircle = styled.div`
  background-color: ${({ theme }): string => theme.primary100};
  border-radius: 50%;

  position: absolute;

  height: 1180px;
  width: 1180px;

  left: -500px;
  top: 600px;

  z-index: -2;
`;

export const BRSmallCircle = styled.div`
  background-color: ${({ theme }): string => theme.primary500};
  border-radius: 50%;

  position: absolute;

  height: 228px;
  width: 228px;

  left: 78.958vw;
  top: 68vh;

  z-index: -1;
`;

export const BRBigCircle = styled.div`
  background-color: ${({ theme }): string => theme.primary300};
  border-radius: 50%;

  position: absolute;

  height: 755px;
  width: 755px;

  left: 78.958vw;
  top: 65vh;

  z-index: -2;
`;
