import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 4px;
  display: flex;
  flex-flow: column;
  overflow: hidden;

  box-shadow: 0px 15px 5px rgba(0, 0, 0, 0.1), 10px 20px 5px rgba(0, 0, 0, 0.05),
    -10px 20px 5px rgba(0, 0, 0, 0.05);
`;

export const Caption = styled.div`
  background-color: white;
  font-family: ${({ theme }): string => theme.fontHeader};
  padding: 0.75rem 1.25rem;
`;

export const Image = styled.img`
  height: 276px;
  width: 100%;
`;
