import styled from 'styled-components';

export const Container = styled.div`
  margin: auto;
  max-width: 1160px;

  padding: 3rem 1rem 2rem;

  @media only screen and (min-width: 768px) {
    padding: 4rem 2rem 2rem;
  }

  @media only screen and (min-width: 1200px) {
    padding: 5rem 3rem 2rem;
  }
`;

export const InnerContainer = styled.div`
  max-width: 1160px;

  margin-bottom: 4rem;

  @media only screen and (min-width: 768px) {
    margin-bottom: 5rem;
  }

  @media only screen and (min-width: 1200px) {
    margin-bottom: 6rem;
  }
`;

export const Columns = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  margin-bottom: 3rem;

  & > div:first-child {
    padding: 0 0 2rem 0;
  }

  @media only screen and (min-width: 1024px) {
    flex-flow: row;

    & > div:first-child {
      padding: 0 1rem 0 0;
    }
  }
`;

export const EmailLink = styled.a`
    color: ${({ theme }): string => theme.primary500};

    a.visited {
        color: ${({ theme }): string => theme.primary800};
    }
  
    a.hover {
        color: ${({ theme }): string => theme.primary800};
        text-decoration: underline;
    }
  }
`;
